*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container{
    position: fixed;
    top: 25%;
    left: 62%;
    max-width: 25% !important;
}
.login-button{
    padding: 10px;
    gap: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 5px;
    width: 355px;
    height:48px;
    margin-bottom: 2rem;
}
.form-check-input[type=checkbox]{
    border-radius: 1px ; 
    border-color: #F33823;
     
}
.paragraph{
    width: 336px;
    height: 21px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: black;
}
 
.roomfac {
    display: flex;
    
}
.userId{
font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 400;
border: none;
font-size: 1.25rem;
padding: 5px;
color: #000000;
}

.admin{
    padding: 12px;
    gap: 16px;
    background: #FFFCFB;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px; 
}
.notification{
    padding: 12px;
    background: #FFFCFB;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

}
.home-dashboard{
    display: flex;
    flex-direction: column;
}
.dashboard{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    gap: 16px;
    width: 220px;
    height: 48px;
    background: #FFFCFB;
    border-radius: 5px;
    margin-left: 3.5rem;
    flex: none;
    order: 0;
    flex-grow: 0;
}
 

.manage-templates{
display: flex;
flex-direction: row;
align-items: center;
padding: 12px 24px;
gap: 16px;
text-decoration: none;
color: white;
width: 220px;
height: 48px;
border-radius: 5px;
background:  #FFFCFB;
margin-left: 3.5rem;
flex: none;
order: 2;
flex-grow: 0;
}
 
.settings{
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 12px 24px;
gap: 16px;
width: 220px;
height: 48px;
background: #FFFCFB;
margin-left: 3.5rem;
border-radius: 5px;
flex: none;
order: 3;
flex-grow: 0;
 
}


.dropdown{
    display: flex;
flex-direction: row;
align-items: flex-start;
gap: 16px;

width: 220px;
height: 48px;

background: #FFFCFB;
margin-left: 2.6rem;
border-radius: 5px;
flex: none;
order: 3;
flex-grow: 0;

}
.logout{
display: flex;
flex-direction: row;
align-items: center;
padding: 12px 24px;
gap: 16px;
width: 220px;
height: 48px;
background: #FFFCFB;
margin-left: 3.5rem;
border-radius: 5px;
flex: none;
order: 4;
flex-grow: 0;
}

.add-template{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    font-family: 'IBM Plex Sans';
}

.template-label{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;   
    margin-top: 20px;
}
.product-label{
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;  
        margin-top: 10px;
}
 

.template-name{
    padding: 5px 10px;
    width: 323px;
    height: 57px;
    background: #FFFFFF;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 1.4rem;
    
}

.product-name{
    padding: 5px 10px;
    width: 323px;
    height: 57px;
    background: #FFFFFF;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 1.4rem;
}

.images{
    margin-top: 3rem;
    margin-bottom: 3rem
}
.images-button{
    display: flex;
    justify-content: space-between;
    width: 96%;
     
}
.images-button:hover{
    cursor: pointer;
}
.upload-images{
display: flex;
justify-content: space-around;
align-items: center; 
padding: 0px 32px;
gap: 16px;
width: 204px;
height: 48px;
left: 0px;
top: 0px;

background: #F33823;
border-radius: 5px;
}
.button{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 15px 32px;
gap: 10px;
border: antiquewhite;
width: 203px;
height: 48px;
background: #F33823;
border-radius: 5px;
color: #FFFFFF;
flex: none;
order: 1;
flex-grow: 0;
}
.text-download{
width: 232px;
height: 18px;
left: 0px;
top: 52px;
font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;

color: rgba(0, 0, 0, 0.5);

}

.first-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 11px;
    width: 222px;
    height: 121px;
    background: #FFF6F5;
    box-shadow: -3px 4px 4px rgba(255, 33, 8, 0.25);
    border-radius: 5px;
}

.second-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 11px;
    width: 222px;
    height: 121px;
    background: #FFF6F5;
    box-shadow: -3px 4px 4px rgba(255, 33, 8, 0.25);
    border-radius: 5px;
}

.third-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 11px;
    width: 223px;
    height: 121px;
    background: #FFF6F5;
    box-shadow: -3px 4px 4px rgba(255, 33, 8, 0.25);
    border-radius: 5px;
}

.fourth-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 11px;
    width: 222px;
    height: 121px;
    background: #FFF6F5;
    box-shadow: -3px 4px 4px rgba(255, 33, 8, 0.25);
    border-radius: 5px;
}

.first-card-title{
    width: 190px;
    height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.second-card-title{
    width: 190px;
    height: 18px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}

.third-card-title{
    width: 191px;
    height: 18px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.fourth-card-title{
width: 190px;
height: 18px;

font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
color: rgba(0, 0, 0, 0.7);
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}

 
 
/* .search-button{
    position: absolute;
    right: 155px;
    top: 265.5px;
} */


.manage-templates-text{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px; 
}

.add-templates-header{
    display: flex;
align-items: center;
padding: 10px 24px;
gap: 110px; 
height: 40px;
color: white;
background: #F33823;
border-radius: 5px; 
font-weight: 350px;
font-size: 16px;
}
.add-templates-header:hover{
    cursor: pointer;
}
.plus{
    font-size: 35px;
    color: #FFFFFF;
    text-align: center;
    margin-left: 35px;
    font-weight: 350px;
    margin-top: -5px;
}
 
a{
    text-decoration: none;
    color: white;
}
.active_class{
    color: #FFFFFF;
}
.unactive{
    color: #000000;
}
#active_class_dashboard{
    background: #F33823;
}
.active_class:hover{ 
    color:white;
}
.text_decoration_none{
    text-decoration: none;
}

.table-container{
width: 1000px;
height: 419px;
background: #FFFCFB;
box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
border-radius: 5px;
}

.dashboard-paragraph{
    position: absolute;
    left: 265px;
    top: 110px;
}

.main-image{
    position: fixed;
    width:  1200px;
    height: 762px;
    left: -451px;
    top: -21px;
}
.card-paragraph{
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    font-family: 'IBM Plex Sans';
    font-style: normal;
}

#input-placeholder::placeholder{
    padding: 30px;
}
#emailId{
    padding: 10px;
}
#passwordId{
    padding: 10px;
}

#emailId::placeholder{
    padding: 115px;
}
#passwordId::placeholder{
    padding: 115px;
}

.preview-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .image-thumbnail {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .image-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
.select-label{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;
    
       
}

.view-select-label{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;
}
.view-select-label-category{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;
    margin-right: 42px;
}

.select-label-competitor{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: -5px;
}
.select-dropdown{
    padding: 5px 10px;
    width: 325px;
    height: 57px;
    background: #FFFFFF;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 2rem;
    margin-bottom: 20px;
} 
 
